<template>
  <div class="main-container" :style="backgroundMainContainer">
    <!-- Winner pop up -->
    <div class="winner-popup" v-if="this.state === RAFFLE_STATES.COMPLETED">
      <div :style="backgroundWinner">
        <player-avatar :player="this.winner" :scale="5"></player-avatar>
        <h1 class="is-size-2">{{ this.winner.username }}</h1>
      </div>
    </div>

    <!-- Sorteo deshabilitado pop up -->
    <div class="winner-popup" v-if="this.state === RAFFLE_STATES.NOT_READY">
      <div class="no-gap">
        <h2 class="is-size-2 has-text-weight-bold">
          {{ $t("exp_raffle.screenRaffleOffTitle") }}
        </h2>
        <p class="is-size-5">
          {{ $t("exp_raffle.screenRaffleOffDescription") }}
        </p>
      </div>
    </div>

    <div class="general-container">
      <span v-if="this.winner" class="winner-class"></span>

      <svg
        width="85"
        height="91"
        viewBox="0 0 85 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="left-arrow"
      >
        <path
          d="M81 38.7654C86.3333 41.8446 86.3333 49.5426 81 52.6218L18 88.9948C10 93.6137 3.44009e-06 87.8401 3.84388e-06 78.6025L6.72087e-06 12.7846C7.12466e-06 3.54699 10 -2.2265 18 2.3923L81 38.7654Z"
        />
      </svg>

      <svg
        width="59"
        height="68"
        viewBox="0 0 59 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="right-arrow"
      >
        <path
          d="M6.01405 30.5343C3.34364 32.0732 3.34364 35.9268 6.01404 37.4657L56.0014 66.272C57.3347 67.0404 59 66.078 59 64.5391L59 3.46087C59 1.92199 57.3347 0.959642 56.0014 1.728L6.01405 30.5343Z"
        />
      </svg>

      <transition-group
        class="rafflePlayerList"
        appear
        @before-enter="userBeforeEnter"
        @enter="userEnter"
        @leave="userLeave"
        tag="ul"
      >
        <li
          v-for="(player, index) in playersAnotados"
          :key="player.dbid + player.animationId + index"
          class="rafflePlayer"
        >
          <div class="rafflePlayerAvatar">
            <player-avatar :player="player" :scale="2.5"></player-avatar>
          </div>
          <p>{{ player.username }}</p>
          <span class="chances has-text-weight-bold is-size-4">{{
            amountOfChances(player)
          }}</span>
        </li>

        <article class="user rafflePlayer skeleton" v-for="i in media" :key="i">
          <figure class="rafflePlayerAvatar">
            <p class="">
              <b-skeleton circle width="50px" height="50px"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <b-skeleton height="20px" width="200px"></b-skeleton>
              </p>
            </div>
          </div>
        </article>
      </transition-group>
    </div>

    <qr-box />

    <div class="card-container">
      <div class="card-image">
        <img :src="backgroundRafflePrizes" alt="" />
      </div>

      <div class="card-description">
        <h3>{{ $t("exp_raffle.screenTitle") }}</h3>
        <p>{{ $t("exp_raffle.screenDescription") }}</p>
      </div>
    </div>

    <div class="new-users">
      <transition-group
        appear
        @before-enter="moderatedBeforeEnter"
        @enter="moderatedEnter"
        @leave="moderatedLeave"
      >
        <div
          v-for="user in newUsersList"
          class="new-user"
          :style="{ border: '3px solid #' + user?.color }"
          :key="user.dbid"
        >
          <player-avatar :player="user" :scale="2.5" class="new-user-avatar" />
          <div class="new-user-name">
            <h4>{{ user?.username }}</h4>
            <p>{{ $t("exp_raffle.screenNotification") }}</p>
            <p>{{ "with " + amountOfChances(user) + " chances" }}</p>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import "./screenRaffle.scss";
import { RAFFLE_STATES, TOUR_LEADER } from "../constants";
import axios from "axios";
import { removeElement } from "../utils";
import qrBox from "./qrBox.vue";
import gsap from "gsap";

// const faker = [
//   {
//     username: 'Carlos Tejedor',
//     avatar: 0,
//     color: "ff0000",
//     dbid: 'a7987a9u8s7f9a87s9f8a7sf'
//   },
//   {
//     username: 'Elena Ramirez',
//     avatar: 1,
//     color: "00ff00",
//     dbid: 'b7089b8u9s8b7a86s8f9b8s9f'
//   },
//   {
//     username: 'Juan Martinez',
//     avatar: 2,
//     color: "0000ff",
//     dbid: 'c6189c8u9s6c5a43s2f1c3s4f'
//   },
//   {
//     username: 'Ana García',
//     avatar: 3,
//     color: "ffff00",
//     dbid: 'd5288d9u8s5d4a32s1f9d3s4f'
//   },
//   {
//     username: 'Pedro Lopez',
//     avatar: 4,
//     color: "00ffff",
//     dbid: 'e4387e9u7s4e3a21s9f8e7s9f'
//   },
//   {
//     username: 'Maria Rodriguez',
//     avatar: 0,
//     color: "ff00ff",
//     dbid: 'f3486f9u6s3f2a10s9f7e6s5f'
//   },
//   {
//     username: 'David Fernandez',
//     avatar: 1,
//     color: "008080",
//     dbid: 'g2585g9u5s2g1a09s8f7g6s4f'
//   },
//   {
//     username: 'Laura Perez',
//     avatar: 2,
//     color: "800080",
//     dbid: 'h1684h9u4s1h0a98s7f6h5s3f'
//   },
//   {
//     username: 'Miguel Sanchez',
//     avatar: 3,
//     color: "800000",
//     dbid: 'i0783i9u3s0i9a87s6f5i4s2f'
//   },
//   {
//     username: 'Sofia Gomez',
//     avatar: 4,
//     color: "008000",
//     dbid: 'j9182j8u2s9j8a76s5f4j3s1f'
//   },
//   {
//     username: 'Alejandro Ruiz',
//     avatar: 0,
//     color: "ffff80",
//     dbid: 'k8281k8u1s8k7a65s4f3k2s0f'
//   },
//   {
//     username: 'Paula Hernandez',
//     avatar: 1,
//     color: "8000ff",
//     dbid: 'l7380l7u0s7l6a54s3f2l1s9f'
//   },
//   {
//     username: 'Diego Torres',
//     avatar: 2,
//     color: "00ff80",
//     dbid: 'm6479m6u9s6m5a43s2f1m0s8f'
//   },
//   {
//     username: 'Carmen Jimenez',
//     avatar: 3,
//     color: "804000",
//     dbid: 'n5578n5u8s5n4a32s1f9n8s7f'
//   },
//   {
//     username: 'Andrea Vazquez',
//     avatar: 4,
//     color: "00ffff",
//     dbid: 'o4677o4u7s4o3a21s9f8o7s6f'
//   },
//   {
//     username: 'Javier Martin',
//     avatar: 0,
//     color: "ff0080",
//     dbid: 'p3776p3u6s3p2a10s9f7p6s5f'
//   },
//   {
//     username: 'Natalia Diaz',
//     avatar: 1,
//     color: "800080",
//     dbid: 'q2875q2u5s2q1a09s8f7q5s4f'
//   },
//   {
//     username: 'Sara Rubio',
//     avatar: 2,
//     color: "ff8000",
//     dbid: 'r1974r1u4s1r0a98s7f6r4s3f'
//   },
//   {
//     username: 'Daniel Castro',
//     avatar: 3,
//     color: "0080ff",
//     dbid: 's1073s0u3s0s9a87s6f5s3s2f'
//   },
//   {
//     username: 'Isabel Nuñez',
//     avatar: 4,
//     color: "8000ff",
//     dbid: 't0172t9u2s9t8a76s5f4t2s1f'
//   }
// ];

const faker = [] 
export default {
  components: {
    qrBox,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      RAFFLE_STATES: RAFFLE_STATES,
      winner: null,
      state: RAFFLE_STATES.WAITING_FOR_PLAYERS, // NOT_READY, WAITING_FOR_PLAYERS, RUNNING, COMPLETED
      playersAnotados: [...faker],
      winners: [],
      queuedPlayers: [],
      newUsersList: [], //lista de pop ups
      media: 10, // Cantidad de skeletons
      animationSpeed: 2000,
      isAnimationRunning: false,
      minAmountPlayers: 10, //Cantidad minima de users para sortear
      matchId: null, // fecha de comienzo del partido. agrupar ganadores dentro de una misma ronda
    };
  },
  mounted() {
    this.GenerateMatchId();
    this.$sfxLoad(this.options.sounds,
      [
        'waitingplayers',
        'click',
        'raffling',
        'winnerscreen'
      ]
    );

    document.addEventListener("keydown", this.KeyListener); 

    this.$sfxPlay("waitingplayers", { loop: true, fadein: true });

    // this.playersAnotados = [...faker]
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.KeyListener)
  },
  watch: {
    state(newState) {
      this.$socket.client.emit("roomPlayersMessage", {
        type: "currentStatus",
        currentStatus: newState,
      });
      if (
        newState !== RAFFLE_STATES.WAITING_FOR_PLAYERS &&
        this.amountOfPlayers < this.minAmountPlayers
      ) {
        this.media = 10;
      }
    },
    amountOfPlayers(newAmount) {
      if (
        this.state === RAFFLE_STATES.WAITING_FOR_PLAYERS &&
        newAmount >= this.minAmountPlayers &&
        !this.isAnimationRunning
      ) {
        this.slowAnimation();
      }
    },
    immediate: true,
  },
  computed: {
    backgroundWinner() {
      return {
        backgroundImage: this.options?.screenBackgroundWon?.url
          ? `url(${this.options.screenBackgroundWon.url})`
          : null,
      };
    },
    backgroundRafflePrizes() {
      return this.options?.imageRaffle?.url
        ? this.options.imageRaffle.url
        : null;
    },
    backgroundMainContainer() {
      if (this.options?.screenBackgroundImage) {
        return {
          backgroundImage: `url(${this.options.screenBackgroundImage})`,
        };
      } else if (this.options?.screenBackgroundColor) {
        return {
          backgroundColor: this.options.screenBackgroundColor,
        };
      } else return null;
    },
    amountOfPlayers() {
      return this.playersAnotados.length;
    },
  },
  methods: {
    GenerateMatchId() {
      this.matchId = new Date().getTime();
    },
    async runRaffle(){

      if(this.state !== RAFFLE_STATES.WAITING_FOR_PLAYERS) return

      if(this.playersAnotados.length < this.minAmountPlayers) return

      //El sorteo solo se ejecuta si hay un minimo de jugadores
      this.state = RAFFLE_STATES.RUNNING;
      
      this.isAnimationRunning = false;
      // this.playersAnotados = shuffleArray(this.playersAnotados);
      // await this.$timeout(400);
      // this.playersAnotados = shuffleArray(this.playersAnotados);
      // await this.$timeout(400);
      // this.playersAnotados = shuffleArray(this.playersAnotados);
      // await this.$timeout(400);

      this.media = 0;

      const raffleDuration = 20;
      this.$sfxStop("waitingplayers");

      this.$sfxPlay("raffling");

      // this.animationSpeed = 500;
      // for (let i=0; i < 2; i++) {
      //   const elementoEliminado = this.playersAnotados.pop();
      //   this.playersAnotados.unshift(elementoEliminado);
      //   this.$sfxPlay("click");
      //   await this.$timeout(this.animationSpeed);
      // }

      // Pausa
      // await this.$timeout(1000);

      this.animationSpeed = 200;
      for (let i = 0; i < raffleDuration; i++) {
        const elementoEliminado = this.playersAnotados.shift(); // Elimina el primer elemento y lo guarda
        this.playersAnotados.push(elementoEliminado);
        this.$sfxPlay("click");

        await this.$timeout(this.animationSpeed);
        this.animationSpeed += 50
      }

      await this.$timeout(1500);

      this.winner = this.playersAnotados[4]; //El ganador siempre va a ser el 5to de la fila.

      this.state = RAFFLE_STATES.COMPLETED;

      this.$sfxStop("raffling");
      this.$sfxPlay("winnerscreen");

      // this.$socket.client.emit("roomPlayersMessage", {
      //   type: "winner",
      //   winnerId: this.winner.dbid,
      // });

      // await axios
      //   .post(process.env.VUE_APP_API_BASE + "/api/rafflewinners", {
      //     user: this.winner.dbid,
      //     matchId: this.matchId,
      //   })
      //   .catch((err) => {
      //     throw err;
      //   });

    },
    resetRaffle(){
      this.changeRaffleState(RAFFLE_STATES.WAITING_FOR_PLAYERS);
      this.animationSpeed = 2000;

      this.$sfxPlay("waitingplayers", { loop: true, fadein: true });

      // Esto hace que el que gano no vuelva a participar
      const newPlayersAnotados = removeElement(
        this.playersAnotados,
        this.winner.dbid
      );

      const filteredQueue = this.queuedPlayers.reduce((acc, curr) => {
        if (!newPlayersAnotados.some((player) => player.dbid === curr.dbid)) {
          acc.push(curr);
          return acc;
        } else return acc;
      }, []);

      filteredQueue.forEach((user) => {
        this.newUsersList.unshift(user); // Lista de pop ups
        this.popUpLeave(user); // Salida de pop ups
      });

      this.playersAnotados = [...newPlayersAnotados, ...filteredQueue];

      this.queuedPlayers = [];

      this.winner = null;

      this.media = 10;

      this.getWinners();
    },
    async slowAnimation() {
      this.media = 0;
      this.isAnimationRunning = true;

      for (let i = 0; this.state === RAFFLE_STATES.WAITING_FOR_PLAYERS; i++) {
        if (this.state !== RAFFLE_STATES.WAITING_FOR_PLAYERS) break;

        this.$sfxPlay("click");

        const newArr = [this.playersAnotados[this.playersAnotados.length - 1]];
        newArr.push(
          ...this.playersAnotados.slice(0, this.playersAnotados.length - 1)
        );
        this.playersAnotados = [...newArr];

        await this.$timeout(this.animationSpeed);
      }
    },
    changeRaffleState(newState) {
      this.state = newState;
    },
    async getWinners() {
      await axios
        .get(
          process.env.VUE_APP_API_BASE +
            `/api/rafflewinners/?limit=999&where[matchId][equals]=${this.matchId}`
        )
        .then((res) => {
          this.winners = res.data.docs;
        })
        .catch((err) => {
          throw err;
        });
    },

    userBeforeEnter(el) {
      el.style.transform = "translateY(-100px)";
    },
    userEnter(el) {
      gsap.to(el, {
        y: "0px",
        duration: this.animationSpeed / 1000,
        ease: "linear",
      });
    },
    userLeave(el) {
      el.style.position = "absolute";
    },

    moderatedBeforeEnter(el) {
      el.style.transform = "translateX(500px)";
      el.style.opacity = "0";
    },
    moderatedEnter(el) {
      gsap.to(el, {
        x: "0px",
        duration: 0.5,
        ease: "power1.out",
        opacity: "1",
      });
    },
    moderatedLeave(el) {
      gsap.to(el, {
        x: "1000px",
        duration: 0.5,
        ease: "power1.out",
        opacity: "0",
      });
    },
    popUpLeave(user) {
      setTimeout(() => {
        const updatedList = [...this.newUsersList].filter(
          (a) => a.dbid !== user.dbid
        );
        this.newUsersList = updatedList;
      }, 5000);
    },
    amountOfChances(user) {
      //Esto determina las chances segun cantidad de achievements para sumar al user por primera vez
      if (this.options?.chances === "oneChance") {
        return 1;
      } else if (this.options?.chances === "achievementMultiplier") {
        if (user.achievements?.total === 0) {
          return 1;
        } else {
          return user.achievements?.total;
        }
      } else return 1;
    },
    updateAppearences(isInPlayerAnotados, isInQueuedPlayers, user, amount) {
      if (isInPlayerAnotados) {
        this.playersAnotados.map((player) => {
          if (player.dbid === user.dbid) {
            player.achievements.total = amount;
          }
        });
        console.log("players Anotados luego del update ", this.playersAnotados);
      }
      if (isInQueuedPlayers) {
        this.queuedPlayers.map((player) => {
          if (player.dbid === user.dbid) {
            player.achievements.total = amount;
          }
        });
      }
    },
    KeyListener(evt) {

    if(evt.key.toLowerCase() === 'n') {

      console.log(this.state)

      switch(this.state) {
        case RAFFLE_STATES.WAITING_FOR_PLAYERS:
          this.runRaffle();
          break;
        case RAFFLE_STATES.COMPLETED:
          this.resetRaffle();
      }
     
    }

    },

  },

  sockets: {
    playerJoined(user) {
      this.amount;
      this.$socket.client.emit("messageToPlayer", {
        to: user.socketid,
        msg: "currentStatus",
        data: { currentStatus: this.state },
      });
      if (user.role !== TOUR_LEADER) {
        const isInPlayerAnotados = this.playersAnotados.some(
          (player) => player.dbid == user.dbid
        );
        const isInQueuedPlayers = this.queuedPlayers.some(
          (player) => player.dbid == user.dbid
        );
        const isWinner = this.winners.some(
          (winner) => winner.user.id === user.dbid
        );
        if (isWinner) {
          // Le aviso a este player que esta en la lista de ganadores previos
          // Eso lo hago para que no se muestre el pop up de que se anoto
          // Y que sepa que no puede participar
          this.$socket.client.emit("messageToPlayer", {
            to: user.socketid,
            msg: "playerIsPreviousWinner",
          });
        }
        const isRunning = this.state === RAFFLE_STATES.RUNNING;
        const isCompleted = this.state === RAFFLE_STATES.COMPLETED;
        const isWaitingForPlayers =
        this.state === RAFFLE_STATES.WAITING_FOR_PLAYERS;

        let amountOfChances = this.amountOfChances(user);

        if (!isInPlayerAnotados && !isWinner) {
          if (isWaitingForPlayers) {
            for (let i = 0; i < amountOfChances; i++) {
              let newUser = {
                ...user,
                animationId: i,
                appearences: amountOfChances,
              }; //usamos el animationId para diferenciar cada elemento en la animacion
              this.playersAnotados.push(newUser);
            }

            this.newUsersList.unshift(user); // Lista de pop ups
            this.popUpLeave(user); // Salida de pop ups
          } else if ((isRunning || isCompleted) && !isInQueuedPlayers) {
            for (let i = 0; i < amountOfChances; i++) {
              let newUser = {
                ...user,
                animationId: i,
                appearences: amountOfChances,
              }; //usamos el animationId para diferenciar cada elemento en la animacion
              this.queuedPlayers.push(newUser);
            }
          }
        }
      }
    },
    async playerList() {
      await this.getWinners();
      this.state = RAFFLE_STATES.WAITING_FOR_PLAYERS;
      this.playersAnotados = this.$store.state.space.players.reduce(
        //chequea que los usuarios conectados no hayan ganado previamente
        (acc, curr) => {
          if (!this.winners.some((winner) => winner.user.id === curr.dbid)) {
            if (curr.role !== TOUR_LEADER) {
              //Y que no sean tour leader

              let amountOfChances = this.amountOfChances(curr);

              for (let i = 0; i < amountOfChances; i++) {
                let newUser = { ...curr, animationId: i }; //usamos el animationId para diferenciar cada elemento en la animacion
                acc.push(newUser);
              }
            }
            return acc;
          } else return acc;
        },
        []
      );
    },

    onTrigger() {
      this.runRaffle()
    },
    onRaffleReset() {
      this.resetRaffle()
    },
    onDisableRaffle() {
      this.changeRaffleState(RAFFLE_STATES.NOT_READY); //TODO: falta chequear cuando se vuelve a habilitar que vuelva a meter a los players
      this.$sfxStop("waitingplayers");
    },
    onRaffleEnabled() {
      // kick players to menu
      this.$socket.client.emit("kickPlayersToMenu");
      location.reload();
    },
    achievementCreated({ user }) {
      const qtyInPlayersAnotados = this.playersAnotados.reduce((acc, curr) => {
        if (curr.dbid === user.id) acc = acc + 1;
        return acc;
      }, 0);

      const qtyInQueuedPlayers = this.queuedPlayers.reduce((acc, curr) => {
        if (curr.dbid === user.id) acc = acc + 1;
        return acc;
      }, 0);

      if (qtyInQueuedPlayers === 0 && qtyInPlayersAnotados === 0) return; //Si no esta en ninguna lista no hacer nada

      if (this.options?.chances === "oneChance")
        return; //si solo suma una chance, no hacer nada
      else if (this.options?.chances === "achievementMultiplier") {
        if (qtyInPlayersAnotados + qtyInQueuedPlayers === 1) {
          //si tiene una sola aparicion buscamos el player y chequeamos si tiene 0 achievements

          if (user.achievements.total === 1) {
            //si ya esta anotado pero este es su primer achievement, actualiza el valor de achievements pero no suma chances
            this.updateAppearences(
              !!qtyInPlayersAnotados,
              !!qtyInQueuedPlayers,
              user,
              1
            );

            return;
          }
        }

        const player = qtyInPlayersAnotados
          ? this.playersAnotados.find((player_) => player_.dbid === user.id)
          : this.queuedPlayers.find((player_) => player_.dbid === user.id);

        if (this.state === RAFFLE_STATES.WAITING_FOR_PLAYERS) {
          const amountOfChances = user.achievements.total;

          this.updateAppearences(
            !!qtyInPlayersAnotados,
            !!qtyInQueuedPlayers,
            player,
            amountOfChances
          );

          let newUser = {
            ...player,
            animationId: amountOfChances,
            achievements: { total: amountOfChances },
          };
          this.playersAnotados.push(newUser);

          this.newUsersList.unshift(newUser); // Lista de pop ups
          this.popUpLeave(newUser); // Salida de pop ups
        } else if (
          this.state === RAFFLE_STATES.COMPLETED ||
          this.state === RAFFLE_STATES.RUNNING
        ) {
          const amountOfChances = user.achievements.total;
          this.updateAppearences(
            !!qtyInPlayersAnotados,
            !!qtyInQueuedPlayers,
            player,
            amountOfChances
          );

          let newUser = {
            ...player,
            animationId: amountOfChances,
            achievements: { total: amountOfChances },
          };

          this.queuedPlayers.push(newUser);
        }
      }
    },
  },
};
</script>
